import React from 'react'
import { graphql } from 'gatsby'
import PageTemplateDetails from '../components/PageTemplateDetails'
import Page from '../components/Page'
import MainContent from '../components/MainContent'

class PageTemplate extends React.Component {
  render() {
    return (
      <Page {...this.props}>
        <MainContent>
          <PageTemplateDetails {...this.props} />
        </MainContent>
      </Page>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query PageBySlug($slug: String!) {
    ...siteQuery
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        date
        description
      }
    }
  }
`
